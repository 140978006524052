import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  tirage: null,
};

const slice = createSlice({
  name: "tirage",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TIRAGE
    getTirageSuccess(state, action) {
      state.isLoading = false;
      state.tirage = action.payload;
    },

    // UPDATE TIRAGE
    updateTirageSuccess(state, action) {
      state.tirage = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateTirageSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getTirage() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/dashboard-supervisor/tirages`);
      dispatch(slice.actions.getTirageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateTirage(data) {
  const response = await axios.post(`/api/dashboard-supervisor/tirages`, data);
  return response.data;
}

export async function deleteTirage(id) {
  const response = await axios.delete(`/api/dashboard-supervisor/tirage/${id}`);
  return response.data;
}
