import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  reportusers: null,
};

const slice = createSlice({
  name: "reportuser",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORT USERS
    getReportUsersSuccess(state, action) {
      state.isLoading = false;
      state.reportusers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReportUsers(
  filterStartDate,
  filterEndDate,
  filterTirage,
  filterSuccursale,
  filterUser
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard-supervisor/report-users?start_date=${filterStartDate}&end_date=${filterEndDate}&tirages=${filterTirage}&succursale=${filterSuccursale}&user=${filterUser}`
      );
      dispatch(slice.actions.getReportUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
