import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
// @mui
import { Box, Button, Typography, Container } from "@mui/material";
// hooks
import useAuth from "../hooks/useAuth";
import { SeverErrorIllustration } from "../assets";
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  permission: PropTypes.string, // Example 'play_multiplayer'
  children: PropTypes.node,
};

const RootStyle = styled(Container)(({ theme }) => ({
  display: "flex",
  height: "100%",
  alignItems: "center",
}));

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ permission, children }) {
  const { isAuthenticated, user } = useAuth();

  if (!user?.user_permissions_list?.includes(permission) && isAuthenticated) {
    return (
      <RootStyle>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Sorry {user?.first_name}!, you are not authorized to this section,
            please try again later or contact the support.
          </Typography>

          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 5 } }} />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Back to Home
          </Button>
        </Box>
      </RootStyle>
    );
  }
  return <>{children}</>;
}
