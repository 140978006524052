import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  limit_boules_tirages: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "limitbouletirage",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LIMIT BOULE TIRAGE
    getLimitBouleTirageSuccess(state, action) {
      state.isLoading = false;
      state.limit_boules_tirages = action.payload;
    },

    // ADD LIMIT BOULE TIRAGE
    addLimitBouleTirageSuccess(state, action) {
      const limitbouletirage = action.payload;
      state.limit_boules_tirages.data.push(limitbouletirage);
      state.limit_boules_tirages.count += 1;
    },

    // UPDATE LIMIT BOULE TIRAGES
    updateLimitBouleTirageSuccess(state, action) {
      const limitbouletirage = action.payload;
      if (state.limit_boules_tirages && state.limit_boules_tirages.data) {
        const limitbouletirageIndex = state.limit_boules_tirages?.data?.findIndex(
          (obj) => obj.id === limitbouletirage.id
        );
        if (limitbouletirageIndex !== -1) {
          state.isLoading = false;
          state.limit_boules_tirages.data[limitbouletirageIndex] = limitbouletirage;
        }
      } else {
        state.limit_boules_tirages = { data: [] };
        state.limit_boules_tirages.data.push(limitbouletirage);
      }
    },

    // DELETE LIMIT BOULE TIRAGE
    deleteLimitBouleTirageSuccess(state, action) {
      const deletedLimitBouleTirageId = action.payload;
      state.limit_boules_tirages.data = state.limit_boules_tirages.data.filter(
        (limitbouletirage) => limitbouletirage.id !== deletedLimitBouleTirageId
      );
      state.isLoading = false;
    },

    // DELETE LIMIT BOULE TIRAGES
    deleteLimitBouleTiragesSuccess(state, action) {
      const deletedLimitBouleTirageIds = action.payload;
      state.limit_boules_tirages.data = state.limit_boules_tirages.data.filter(
        (limitbouletirage) => !deletedLimitBouleTirageIds.includes(limitbouletirage.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addLimitBouleTirageSuccess,
  updateLimitBouleTirageSuccess,
  deleteLimitBouleTirageSuccess,
  deleteLimitBouleTiragesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLimitBouleTirages(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard-supervisor/limit-boule-tirage?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getLimitBouleTirageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getLimitBouleTirage(keyId) {
  const response = await axios.get(
    `/api/dashboard-supervisor/limit-boule-tirage-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addLimitBouleTirage(data) {
  const response = await axios.post(`/api/dashboard-supervisor/limit-boule-tirage`, data);
  return response.data;
}

export async function updateLimitBouleTirage(data) {
  const response = await axios.put(`/api/dashboard-supervisor/limit-boule-tirage`, data);
  return response.data;
}

export async function deleteLimitBouleTirage(keyId) {
  const response = await axios.delete(`/api/dashboard-supervisor/limit-boule-tirage/${keyId}/`);
  return response.data;
}

export async function deleteLimitBouleTirages(keyIds) {
  const response = await axios.delete(`/api/dashboard-supervisor/limit-boule-tirage-id`, {
    data: { keyIds },
  });
  return response.data;
}
