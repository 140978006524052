import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import PhoneNumber from "./PhoneNumber";

// ----------------------------------------------------------------------

RHFPhoneField.propTypes = {
  name: PropTypes.string,
};

export default function RHFPhoneField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PhoneNumber
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          variant="outlined"
          defaultCountry={"ht"}
          onlyCountries={["ht", "us", "cl", "br", "do"]}
          {...other}
        />
      )}
    />
  );
}
