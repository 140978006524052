import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  fees: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
};

const slice = createSlice({
  name: "fee",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FEES
    getFeeSuccess(state, action) {
      state.isLoading = false;
      state.fees = action.payload;
    },

    // ADD FEE
    addFeeSuccess(state, action) {
      const fee = action.payload;
      state.fees.data.push(fee);
      state.fees.count += 1;
    },

    // UPDATE FEES
    updateFeeSuccess(state, action) {
      const fee = action.payload;
      if (state.fees && state.fees.data) {
        const feeIndex = state.fees?.data?.findIndex(
          (obj) => obj.id === fee.id
        );
        if (feeIndex !== -1) {
          state.isLoading = false;
          state.fees.data[feeIndex] = fee;
        }
      } else {
        state.fees = { data: [] };
        state.fees.data.push(fee);
      }
    },

    // DELETE FEE
    deleteFeeSuccess(state, action) {
      const deletedFeeId = action.payload;
      state.fees.data = state.fees.data.filter(
        (fee) => fee.id !== deletedFeeId
      );
      state.isLoading = false;
    },

    // DELETE FEES
    deleteFeesSuccess(state, action) {
      const deletedFeeIds = action.payload;
      state.fees.data = state.fees.data.filter(
        (fee) => !deletedFeeIds.includes(fee.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addFeeSuccess,
  updateFeeSuccess,
  deleteFeeSuccess,
  deleteFeesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getFees(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard-supervisor/feegenerales?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getFeeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getFee(keyId) {
  const response = await axios.get(
    `/api/dashboard-supervisor/feegenerale-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addFee(data) {
  const response = await axios.post(`/api/dashboard-supervisor/feegenerales`, data);
  return response.data;
}

export async function updateFee(data) {
  const response = await axios.put(`/api/dashboard-supervisor/feegenerales`, data);
  return response.data;
}

export async function deleteFee(keyId) {
  const response = await axios.delete(`/api/dashboard-supervisor/feegenerales`, {
    data: { keyId },
  });
  return response.data;
}

export async function deleteFees(keyIds) {
  const response = await axios.delete(`/api/dashboard-supervisor/feegenerale-id`, {
    data: { keyIds },
  });
  return response.data;
}
