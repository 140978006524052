import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  quizpricings: [],
  isPay: false,
  isGameOver: false,
  gameSession: null,
  selectedQuizpricingId: 1,
};

const slice = createSlice({
  name: "quizpricing",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET QUIZPRICINGS
    getQuizpricingsSuccess(state, action) {
      state.isLoading = false;
      state.quizpricings = action.payload;
    },

    // SELECT QUIZPRICING
    selectQuizpricing(state, action) {
      const quizpricingId = action.payload;
      state.selectedQuizpricingId = quizpricingId;
    },

    // CLOSE GAME SESSION
    closeGameSession(state) {
      state.isPay = false;
      state.isGameOver = false;
      state.gameSession = null;
    },

    // OVER GAME SESSION
    overGameSession(state, action) {
      state.isPay = false;
      state.isGameOver = true;
      state.gameSession = action.payload;
    },

    // PAY
    payPlanSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.isPay = true;
      state.gameSession = action.payload;
    },

    // UPDATE GAME
    updateGameSession(state, action) {
      state.error = null;
      state.gameSession = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  selectQuizpricing,
  closeGameSession,
  overGameSession,
  updateGameSession,
  payPlanSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function getQuizpricings() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/quiz/plan");
      dispatch(slice.actions.getQuizpricingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function payPlan(planId) {
  const response = await axios.post(`/api/quiz/plan`, {
    planId,
  });
  return response.data;
}
