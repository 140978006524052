import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  succursales: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
  selectsuccursales: null,
};

const slice = createSlice({
  name: "succursale",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SUCCURSALES
    getSuccursaleSuccess(state, action) {
      state.isLoading = false;
      state.succursales = action.payload;
    },
    // GET SUCCURSALES TO SELECT
    getSuccursaleToSelectSuccess(state, action) {
      state.isLoading = false;
      state.selectsuccursales = action.payload;
    },

    // ADD SUCCURSALE
    addSuccursaleSuccess(state, action) {
      const succursale = action.payload;
      state.succursales.data.push(succursale);
      state.succursales.count += 1;
    },

    // UPDATE SUCCURSALE
    updateSuccursaleSuccess(state, action) {
      const succursale = action.payload;
      if (state.succursales && state.succursales.data) {
        const succursaleIndex = state.succursales?.data?.findIndex(
          (obj) => obj.id === succursale.id
        );
        if (succursaleIndex !== -1) {
          state.isLoading = false;
          state.succursales.data[succursaleIndex] = succursale;
        }
      } else {
        state.succursales = { data: [] };
        state.succursales.data.push(succursale);
      }
    },

    // DELETE SUCCURSALE
    deleteSuccursaleSuccess(state, action) {
      const deletedSuccursaleId = action.payload;
      state.succursales.data = state.succursales.data.filter(
        (succursale) => succursale.id !== deletedSuccursaleId
      );
      state.isLoading = false;
    },

    // DELETE SUCCURSALES
    deleteSuccursalesSuccess(state, action) {
      const deletedSuccursaleIds = action.payload;
      state.succursales.data = state.succursales.data.filter(
        (succursale) => !deletedSuccursaleIds.includes(succursale.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addSuccursaleSuccess,
  updateSuccursaleSuccess,
  deleteSuccursaleSuccess,
  deleteSuccursalesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getSuccursales(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard-supervisor/succursales?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getSuccursaleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSuccursalesToSelect() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/dashboard-supervisor/succursales-to-select`);
      dispatch(slice.actions.getSuccursaleToSelectSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getSuccursale(keyId) {
  const response = await axios.get(
    `/api/dashboard-supervisor/succursale-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addSuccursale(data) {
  const response = await axios.post(`/api/dashboard-supervisor/succursales`, data);
  return response.data;
}

export async function updateSuccursale(data) {
  const response = await axios.put(`/api/dashboard-supervisor/succursales`, data);
  return response.data;
}

export async function deleteSuccursale(keyId) {
  const response = await axios.delete(`/api/dashboard-supervisor/succursales/${keyId}/`);
  return response.data;
}

export async function deleteSuccursales(keyIds) {
  const response = await axios.delete(`/api/dashboard-supervisor/succursale-id`, {
    data: { keyIds },
  });
  return response.data;
}
