import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

RHFDateField.propTypes = {
  name: PropTypes.string,
};

export default function RHFDateField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TimePicker
          value={value ? new Date(value) : null}
          onChange={(time) => onChange(time ?? null)}
          format="HH:mm:ss"
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          slotProps={{ textField: { variant: "outlined" } }}
        />
      )}
    />
  );
}
