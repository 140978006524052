import PropTypes from "prop-types";
import { useMemo } from "react";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import useSettings from "../hooks/useSettings";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection } = useSettings();

  const palettes = {
    light: palette.light,
    dark: palette.dark,
    default: palette.default,
    blue: palette.blue,
    orange: palette.orange,
    cyan: palette.cyan,
    red: palette.red,
    purple: palette.purple,
  };

  const selectedPalette = palettes[themeMode];

  const isDark = themeMode === "dark";

  const themeOptions = useMemo(
    () => ({
      palette: selectedPalette,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isDark ? shadows.dark : shadows.light,
      customShadows: isDark ? customShadows.dark : customShadows.light,
    }),
    [selectedPalette, isDark, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
