import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  limit_quantityboules: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "limitquantityboule",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LIMIT QUANTITY BOULE
    getLimitQuantityBouleSuccess(state, action) {
      state.isLoading = false;
      state.limit_quantityboules = action.payload;
    },

    // ADD LIMIT QUANTITY BOULE
    addLimitQuantityBouleSuccess(state, action) {
      const limitquantityboule = action.payload;
      state.limit_quantityboules.data.push(limitquantityboule);
      state.limit_quantityboules.count += 1;
    },

    // UPDATE LIMIT QUANTITY BOULES
    updateLimitQuantityBouleSuccess(state, action) {
      const limitquantityboule = action.payload;
      if (state.limit_quantityboules && state.limit_quantityboules.data) {
        const limitquantitybouleIndex = state.limit_quantityboules?.data?.findIndex(
          (obj) => obj.id === limitquantityboule.id
        );
        if (limitquantitybouleIndex !== -1) {
          state.isLoading = false;
          state.limit_quantityboules.data[limitquantitybouleIndex] = limitquantityboule;
        }
      } else {
        state.limit_quantityboules = { data: [] };
        state.limit_quantityboules.data.push(limitquantityboule);
      }
    },

    // DELETE LIMIT QUANTITY BOULE
    deleteLimitQuantityBouleSuccess(state, action) {
      const deletedLimitQuantityBouleId = action.payload;
      state.limit_quantityboules.data = state.limit_quantityboules.data.filter(
        (limitquantityboule) => limitquantityboule.id !== deletedLimitQuantityBouleId
      );
      state.isLoading = false;
    },

    // DELETE LIMIT QUANTITY BOULES
    deleteLimitQuantityBoulesSuccess(state, action) {
      const deletedLimitQuantityBouleIds = action.payload;
      state.limit_quantityboules.data = state.limit_quantityboules.data.filter(
        (limitquantityboule) => !deletedLimitQuantityBouleIds.includes(limitquantityboule.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addLimitQuantityBouleSuccess,
  updateLimitQuantityBouleSuccess,
  deleteLimitQuantityBouleSuccess,
  deleteLimitQuantityBoulesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLimitQuantityBoules(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard-supervisor/limit-quantity-boule?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getLimitQuantityBouleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getLimitQuantityBoule(keyId) {
  const response = await axios.get(
    `/api/dashboard-supervisor/limit-quantity-boule-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addLimitQuantityBoule(data) {
  const response = await axios.post(`/api/dashboard-supervisor/limit-quantity-boule`, data);
  return response.data;
}

export async function updateLimitQuantityBoule(data) {
  const response = await axios.put(`/api/dashboard-supervisor/limit-quantity-boule`, data);
  return response.data;
}

export async function deleteLimitQuantityBoule(keyId) {
  const response = await axios.delete(`/api/dashboard-supervisor/limit-quantity-boule/${keyId}/`);
  return response.data;
}

export async function deleteLimitQuantityBoules(keyIds) {
  const response = await axios.delete(`/api/dashboard-supervisor/limit-quantity-boule-id`, {
    data: { keyIds },
  });
  return response.data;
}
