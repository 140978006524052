import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled, useTheme, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// hooks
import useAuth from "../../../hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Image from "../../../components/Image";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(0.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const theme = useTheme();
  const { user } = useAuth();

  return (
    <Link
      underline="none"
      color="inherit"
      component={RouterLink}
      to={PATH_DASHBOARD.root}
      sx={{ pt: 0, mt: 0 }}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: alpha(theme.palette.primary.dark, 0.3),
          }),
        }}
      >
        <Image
          src={user?.photo}
          sx={{ maxWidth: isCollapse ? 50 : 130, maxHeight: isCollapse ? 30 : 130 }}
        />
        {/* <Box
          sx={{
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          <Typography variant="subtitle2">{user?.bank_name}</Typography>
        </Box> */}
      </RootStyle>
    </Link>
  );
}
