// i18n
import "./locales/i18n";

// highlight
import "./utils/highlight";

// scroll bar
import "simplebar/src/simplebar.css";

// lightbox
import "react-image-lightbox/style.css";

// map
import "mapbox-gl/dist/mapbox-gl.css";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// redux
import { store, persistor } from "./redux/store";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

import { AuthProvider } from "./contexts/JWTContext";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// ----------------------------------------------------------------------

ReactDOM.render(
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <HashRouter>
                  <App />
                </HashRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// const body = document.getElementsByTagName("body");
// window.addEventListener("keydown", (event) => {
//   if (event.ctrlKey && (event.key === "S" || event.key === "s")) {
//     event.preventDefault();
//     body[0].innerHTML = "sorry, you can't do this 💔";
//   }

//   if (event.ctrlKey && event.key === "C") {
//     event.preventDefault();
//     body[0].innerHTML = "sorry, you can't do this 💔";
//   }
//   if (event.ctrlKey && (event.key === "E" || event.key === "e")) {
//     event.preventDefault();
//     body[0].innerHTML = "sorry, you can't do this 💔";
//   }
//   if (event.ctrlKey && (event.key === "I" || event.key === "i")) {
//     event.preventDefault();
//     body[0].innerHTML = "sorry, you can't do this 💔";
//   }
//   if (event.ctrlKey && (event.key === "K" || event.key === "k")) {
//     event.preventDefault();
//     body[0].innerHTML = "sorry, you can't do this 💔";
//   }
//   if (event.ctrlKey && (event.key === "U" || event.key === "u")) {
//     event.preventDefault();
//     body[0].innerHTML = "sorry, you can't do this 💔";
//   }
// });
// document.addEventListener("contextmenu", function (e) {
//   e.preventDefault();
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
