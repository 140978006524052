import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  questions: [],
};

const slice = createSlice({
  name: "question",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET QUESTIONS
    getQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },

    // ADD QUESTIONS
    createQuestionSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },

    // ADD QUESTION
    addQuestionSuccess(state, action) {
      const question = action.payload;
      state.questions.push(question);
    },

    // UPDATE QUESTIONS
    updateQuestionSuccess(state, action) {
      const question = action.payload;
      const questionIndex = state.questions.findIndex(
        (u) => u.id === question.id
      );
      if (questionIndex !== -1) {
        state.isLoading = false;
        state.questions[questionIndex] = question;
      }
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuestions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/quiz/questions");
      dispatch(slice.actions.getQuestionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createQuestion(data) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/quiz/add-question", {
        new_question: data,
      });
      dispatch(slice.actions.addQuestionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateQuestion(questionId, data) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put("/api/quiz/update-question", {
        questionId: questionId,
        new_question: data,
      });
      dispatch(slice.actions.updateQuestionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bannedQuestion(questionId, banned) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put("/api/quiz/banned-question", {
        questionId: questionId,
        is_live: banned,
      });
      dispatch(slice.actions.updateQuestionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function verifQuestion(questionId, verif) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put("/api/quiz/verif-question", {
        questionId: questionId,
        is_verified: verif,
      });
      dispatch(slice.actions.updateQuestionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
