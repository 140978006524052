import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  primeusers: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "primeuser",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRIME USER
    getPrimeUserSuccess(state, action) {
      state.isLoading = false;
      state.primeusers = action.payload;
    },

    // ADD PRIME USER
    addPrimeUserSuccess(state, action) {
      const primeuser = action.payload;
      state.primeusers.data.push(primeuser);
      state.primeusers.count += 1;
    },

    // UPDATE PRIME USERS
    updatePrimeUserSuccess(state, action) {
      const primeuser = action.payload;
      if (state.primeusers && state.primeusers.data) {
        const primeuserIndex = state.primeusers?.data?.findIndex(
          (obj) => obj.id === primeuser.id
        );
        if (primeuserIndex !== -1) {
          state.isLoading = false;
          state.primeusers.data[primeuserIndex] = primeuser;
        }
      } else {
        state.primeusers = { data: [] };
        state.primeusers.data.push(primeuser);
      }
    },

    // DELETE PRIME USER
    deletePrimeUserSuccess(state, action) {
      const deletedPrimeUserId = action.payload;
      state.primeusers.data = state.primeusers.data.filter(
        (primeuser) => primeuser.id !== deletedPrimeUserId
      );
      state.isLoading = false;
    },

    // DELETE PRIME USERS
    deletePrimeUsersSuccess(state, action) {
      const deletedPrimeUserIds = action.payload;
      state.primeusers.data = state.primeusers.data.filter(
        (primeuser) => !deletedPrimeUserIds.includes(primeuser.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addPrimeUserSuccess,
  updatePrimeUserSuccess,
  deletePrimeUserSuccess,
  deletePrimeUsersSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPrimeUsers(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard-supervisor/prime-user?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getPrimeUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getPrimeUser(keyId) {
  const response = await axios.get(
    `/api/dashboard-supervisor/prime-user-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addPrimeUser(data) {
  const response = await axios.post(`/api/dashboard-supervisor/prime-user`, data);
  return response.data;
}

export async function updatePrimeUser(data) {
  const response = await axios.put(`/api/dashboard-supervisor/prime-user`, data);
  return response.data;
}

export async function deletePrimeUser(keyId) {
  const response = await axios.delete(`/api/dashboard-supervisor/prime-user/${keyId}/`);
  return response.data;
}

export async function deletePrimeUsers(keyIds) {
  const response = await axios.delete(`/api/dashboard-supervisor/prime-user-id`, {
    data: { keyIds },
  });
  return response.data;
}
