import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  customers: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMERS
    getCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
    },

    // ADD CUSTOMER
    addCustomerSuccess(state, action) {
      const customer = action.payload;
      state.customers.data.push(customer);
      state.customers.count += 1;
    },

    // UPDATE CUSTOMER
    updateCustomerSuccess(state, action) {
      const customer = action.payload;
      if (state.customers && state.customers.data) {
        const customerIndex = state.customers?.data?.findIndex(
          (obj) => obj.id === customer.id
        );
        if (customerIndex !== -1) {
          state.isLoading = false;
          state.customers.data[customerIndex] = customer;
        }
      } else {
        state.customers = { data: [] };
        state.customers.data.push(customer);
      }
    },

    // DELETE CUSTOMER
    deleteCustomerSuccess(state, action) {
      const deletedCustomerId = action.payload;
      state.customers.data = state.customers.data.filter(
        (customer) => customer.id !== deletedCustomerId
      );
      state.isLoading = false;
    },

    // DELETE CUSTOMERS
    deleteCustomersSuccess(state, action) {
      const deletedCustomerIds = action.payload;
      state.customers.data = state.customers.data.filter(
        (customer) => !deletedCustomerIds.includes(customer.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addCustomerSuccess,
  updateCustomerSuccess,
  deleteCustomerSuccess,
  deleteCustomersSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCustomers(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard-supervisor/customers?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getCustomerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getCustomer(keyId) {
  const response = await axios.get(`/api/dashboard-supervisor/customer-id?keyId=${keyId}`);
  return response.data;
}

export async function addCustomer(data) {
  const response = await axios.post(`/api/dashboard-supervisor/customers`, data);
  return response.data;
}

export async function updateCustomer(data) {
  const response = await axios.put(`/api/dashboard-supervisor/customers`, data);
  return response.data;
}

export async function deleteCustomer(keyId) {
  const response = await axios.delete(`/api/dashboard-supervisor/customers`, {
    data: { keyId },
  });
  return response.data;
}

export async function deleteCustomers(keyIds) {
  const response = await axios.delete(`/api/dashboard-supervisor/customer-id`, {
    data: { keyIds },
  });
  return response.data;
}
